<template>
  <div>
    <div v-show="exactRoute">
      <!-- TODO: srovnat -->
      <v-btn
        @click="redirectToNewCardForm"
      >Vytvořit novou kartu</v-btn>
      <v-card>
        <v-card-title>
          Karty objektu
          <v-spacer></v-spacer>
          <v-switch
            v-model="dateFilterType"
            :label="dateFilterType ? 'Editováno' : 'Vytvořeno'"
          ></v-switch>
          <vc-date-picker
            v-model="dateFilter"
            is-range
            :columns="2"
            color="blue"
            :popover="{ visibility: 'focus' }">
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  outlined
                  :value="getLocalizedDate(dateFilter.start)"
                  v-on="inputEvents.start"
                  hide-details
                  readonly
                  placeholder="Od"
                  class="rounded-0 border-right-none"
                  dense
                  style="max-width: 120px"
                />
                <v-text-field
                  class="rounded-0 border-left-none"
                  outlined
                  :value="getLocalizedDate(dateFilter.end)"
                  v-on="inputEvents.end"
                  hide-details
                  dense
                  readonly
                  placeholder="Do"
                  style="max-width: 120px"
                />
              </div>
            </template>
          </vc-date-picker>
          <v-select
            multiple
            dense
            outlined
            small-chips
            deletable-chips
            hide-details
            class="rounded-0"
            label="Filtr stavu"
            v-model="approvalProcessStateFilter"
            hide-selected
            :items="approvalProcessStates"
          >
          </v-select>
          <v-form v-model="searchValid" class="d-flex">
            <v-text-field
              v-model.trim="search"
              append-icon="mdi-magnify"
              label="Vyhledat"
              :rules="[validation.required]"
              single-line
              hide-details
              outlined
              ref="searchInput"
              dense
              class="rounded-0"
            ></v-text-field>
            <v-select
              outlined
              class="rounded-0"
              dense
              single-line
              hide-details
              ref="searchTypeInput"
              label="Vyhledat podle"
              :rules="[validation.required]"
              v-model="searchType"
              :items="searchTypes"
            >
            </v-select>
            <v-btn
              color="primary"
              :disabled="!searchValid || runningRequest"
              @click="filterBySearch"
              class="rounded-0"
            >Vyhledat</v-btn>
            <v-btn
              color="error"
              @click="resetSearch()"
              class="rounded-0"
              :disabled="!searchActive || runningRequest"
            >
              Zrušit vyhledávání
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="properties"
          class="elevation-1"
          :loading="runningRequest"
          :server-items-length="totalProperties"
          :options.sync="paginationOptions"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.approvalProcessState="{ item }">
            <v-chip
              :color="getColor(item.approvalProcessState)"
              dark
            >
              {{ getApprovalProcessStateText(item.approvalProcessState) }}
            </v-chip>
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | localizedDate }}
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt">
              {{ item.updatedAt | localizedDate }}
            </span>
            <span v-else>-</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-dialog
              v-if="deleteDialog"
              v-model="deleteDialog"
              width="350"
            >
              <DeleteResource
                :resource="item"
                resource-type="host/properties"
                @delete-success="() => {showDeleteSuccess(); getProperties()}"
                @delete-failure="showDeleteFailure"
              />
            </v-dialog>
            <v-icon
              small
              @click="deleteDialog = true"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import propertiesOverviewHelpers from '@/mixins/local/propertiesOverviewHelpers'
import DeleteResource from '@/components/DeleteResource'
import archiveHelpers from '@/mixins/local/archiveHelpers'

export default {
  name: 'Cards',
  components: { DeleteResource },
  mixins: [propertiesOverviewHelpers, archiveHelpers],
  data () {
    return {
      search: '',
      searchValid: true,
      searchActive: false,
      searchType: null,
      searchTypes: [
        {
          text: 'Název',
          value: 'name'
        }
      ],
      runningRequest: false,
      approvalProcessStateFilter: [],
      dateFilterType: true, // true - edited, false - created
      dateFilter: {
        start: null,
        end: null
      },
      deleteDialog: false,
      headers: [
        {
          text: 'Název',
          align: 'start',
          value: 'name'
        },
        { text: 'Stav', value: 'approvalProcessState' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Poslední editace', value: 'updatedAt' },
        { text: 'Hodnocení', value: 'rating' },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      properties: [],
      totalProperties: 0,
      exactRoute: true
    }
  },
  watch: {
    approvalProcessStateFilter: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    },
    dateFilter: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    },
    dateFilterType: {
      handler () {
        this.loadProperties()
      }
    },
    $route: {
      deep: true,
      handler (newVal, oldVal) {
        // resetovani kroku formu, aby nezustaval step 8, pokud user vytvori(edituje) kartu a prekline na jinou do formulare
        this.$store.state.propertyCard.formStep = 1
        // reload pro pripad, ze by vytvoril/editoval novou kartu a vratil se na prehled
        // na reseni optimalizace neni cas :)
        if (oldVal.name === 'host.properties.create' || oldVal.name === 'host.properties.property') {
          this.getProperties()
        }
      }
    }
  },
  methods: {
    loadProperties () {},
    getProperties () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      let dateFilterTypeTmp = null
      if (this.dateFilter.start) {
        dateFilterTypeTmp = this.dateFilterType ? 'edit' : 'create'
      }
      this.$http.get(`/hosts/${this.loggedUser.id}/properties`, {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search || null,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          dateFilterType: dateFilterTypeTmp,
          from: this.dateFilter.start ? this.dateFilter.start.toISOString().slice(0, 10) : null,
          to: this.dateFilter.end ? this.dateFilter.end.toISOString().slice(0, 10) : null,
          approvalProcessStates: this.approvalProcessStateFilter,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.properties = res.data.properties
          this.totalProperties = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    filterBySearch () {
      this.searchActive = true
      this.getProperties()
    },
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.searchActive = false
      this.getProperties()
    },
    redirectToDetail (property) {
      this.$router.push(`/host/properties/${property.id}`)
    },
    /**
     * Redirects to create property page
     * @return void
     */
    redirectToNewCardForm () {
      this.$router.push({ name: 'host.properties.create' })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  computed: {
    ...mapGetters({
      loggedUser: 'userAuth/user'
    })
  },
  mounted () {
    this.getProperties()
    this.loadProperties = this.debounce(this.getProperties, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
