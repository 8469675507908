export default {
  data () {
    return {
      paginationOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: [],
        sortDesc: []
      },
      approvalProcessStates: [
        {
          text: 'Schválená',
          value: 3
        },
        {
          text: 'Neschválená',
          value: 2
        },
        {
          text: 'Čekající',
          value: 1
        }
      ],
      editedProperty: {},
      editDialog: false
    }
  },
  methods: {
    loadProperties () {},
    getProperties () {},
    getColor (APState) {
      if (APState === 2) return 'red'
      else if (APState === 3) return 'green'
      else return 'orange'
    },
    getApprovalProcessStateText (APState) {
      if (APState === 2) return 'Neschválená'
      else if (APState === 3) return 'Schválená'
      else return 'Čekající'
    },
    changeApprovalProcessState (item) {
      this.editDialog = true
      this.editedProperty = { ...item }
    },
    showApprovalProcessStatesChangeFailure () {
      this.editDialog = false
      this.$store.dispatch('alerts/setAlerts', [{
        message: 'Došlo k chybě',
        type: 'error'
      }])
    },
    showApprovalProcessStatesChangeSuccess () {
      this.editDialog = false
      this.$store.dispatch('alerts/setAlerts', [{
        message: 'Změna stavu proběhla úspěšně',
        type: 'success'
      }])
      this.getProperties()
    }
  },
  watch: {
    paginationOptions: {
      deep: true,
      handler () {
        this.loadProperties()
      }
    }
  }
}
